import request from '@/tools/request';
import { GetList, ListRes, Remove, RemoveRes } from '../types/list';

export const getList: GetList = params => {
  return request({
    params,
    url: '/demo/index/selectPage',
  }) as Promise<ListRes>;
};

export const remove: Remove = params => {
  return request({
    params,
    url: '/demo/index/selectPage',
  }) as Promise<RemoveRes>;
};
