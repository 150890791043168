
import { defineComponent, onMounted, ref } from 'vue';
import MTable from '@/components/table/Table.vue';
import { getRoute } from '@/tools/config';
import { getList, remove } from '../api/list';
import { ElButton, ElInput, ElMessage, ElTableColumn } from 'element-plus';
import { ListItem } from '../types/list';

export default defineComponent({
  name: 'LexionAdmin',
  setup() {
    const tableList = ref<ListItem[]>([
      {
        id: '123',
        store_id: '123',
        content: 'test',
        task_time: '2017-01-22',
      },
    ]);
    const loading = ref(false);

    const pagination = ref({
      total: 0,
      pageSize: 10,
      currentPages: 1,
    });
    const keyword = ref('');
    // 初始化
    const initTable = async (page = 1) => {
      const res = await getList(
        {
          page,
          page_size: pagination.value.pageSize,
          contentLike: keyword.value,
        },
        loading,
      );

      if (!res.status) return;

      const { data } = res;

      tableList.value = res.data?.list || [];

      pagination.value.total = data?.pages?.total || 0;
      pagination.value.pageSize = data?.pages?.page_size || 10;
    };

    // 换页
    const pageChange = cur => {
      pagination.value.currentPages = cur;
      initTable(cur);
    };

    // 搜索
    const search = () => {
      initTable();
    };

    // 删除行
    const deleteData = async id => {
      const { status } = await remove({ id });
      if (status) {
        initTable();
        ElMessage({
          type: 'success',
          message: '删除成功',
        });
      }
    };

    onMounted(() => {
      initTable();
    });

    return {
      tableList,
      loading,
      getRoute,
      pagination,
      pageChange,
      keyword,
      search,
      deleteData,
    };
  },
  components: { MTable, ElInput, ElTableColumn, ElButton },
});
